.spinner {
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;

	&.global {
		background: #ffffff;
		z-index: 2000;
	}

	svg {
		flex: 0 1 120px;
		max-width: 95%;
		max-height: 95%;
	}
}

.logo {
	// filter: drop-shadow(0px 2px 4px rgba(16, 24, 40, 0.06)) drop-shadow(0px 4px 8px rgba(16, 24, 40, 0.1));
	// border-radius: 10px;
	// background: #d9d9d9;
}

.wrapper {
	display: flex;
	align-items: center;
	align-items: center;
}

.title {
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 38px;
	color: themeColor(--mui-palette-text-primary);
}
